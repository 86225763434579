import React, { memo, useCallback, useContext, useState, useEffect, useRef } from 'react';
import './calldisposition.css';
import { AgentContext } from '../AgentContextProvider';
import { ContactContext } from '../ContactContextProvider';
import { useConnected, useDestroy, useCallCompleted, contact } from '../../hooks.js';
import { instanceId, api_url } from '../../config.js';
import { apiDisposition } from '../../services/models/disposition.js';
import TextField from '@material-ui/core/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import DispositionModal from './DispositionModal';

const CallDisposition = (props) => {
  const state = useContext(AgentContext);
  let contactstate = useContext(ContactContext);
  let onContact = contactstate.onContact;
  const isLoggedIn = state.isLoggedIn;
  const modalWidth = props.width;
  const modalHeight = props.height;
  const [customerDisposition, setCustomerDisposition] = useState(null);
  const [dispositions, setDispositions] = useState([]);
  const [selectValues, setSelectValues] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const routingProfile = state.routingProfile;
  const idToken = state.idToken;

  // useRef variables
  const dispositionSetRef = useRef(customerDisposition);
  const selectValuesRef = useRef(dispositions);
  const defaultValuesRef = useRef(defaultValues);

  //Creates reference to the status of the customer disposition (true = Disposition has been set || false = Disposition has not been set)
  useEffect(() => {
    dispositionSetRef.current = customerDisposition;
  }, [customerDisposition]);

  // Creates a reference to the selected dispositions in the Select Element
  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    selectValuesRef.current = selectValues;
    let vals = Object.values(selectValues).map((value) => {
      return value;
    });

    let render = vals.includes('(Choose One)');

    if (render === false && vals.length !== 0) {
      setSubmitDisabled(false);
    } else setSubmitDisabled(true);
  }, [selectValues]);

  // Creates a reference to the Default Values set in the Disposition widget. The values will be reset to the defaults when a contact is destroyed
  //or when an agent clicks the submit button without a contact.
  useEffect(() => {
    defaultValuesRef.current = defaultValues;
  }, [defaultValues]);

//Hook for behavior when Contact is connected
  const onConnected = useCallback(
    (c) => {
  try {
        setCustomerDisposition(false);
              } catch (e) {
        console.error(e);
      }
    },
      [customerDisposition, setCustomerDisposition]
  );
  useConnected(onConnected);
    
  const onCallCompleted = useCallback((c) => {
    if (dispositionSetRef.current == false) {
      setOpenModal(true);
    } else {
      c.clear();
  }
}, []);
  useCallCompleted(onCallCompleted);

const onDestroy = useCallback(() => {
    let form = document.getElementById('Disposition Form');
        setOpenModal(false);
    form.reset();
    setSelectValues(defaultValuesRef.current);
    setSubmitDisabled(true);
}, []);
useDestroy(onDestroy);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
  }
    try {
      getDispositions();
    } catch (e) {
      console.error(e);
    }
  }, [isLoggedIn]);

// Loads the disposition list from the DDB table
  async function getDispositions() {
    console.log(idToken)
    apiDisposition
    .get('',{routingProfile: routingProfile})
      .then((res)=>{
        let items = res;
        let valObject = {};
        Object.entries(items).map(
          ([key, value]) => (valObject[key] = value.map(Object.values)[0][0])
        );
        setDefaultValues(valObject);
        setSelectValues(valObject);
        setDispositions(items);
}
      )
  }

// Updates the selectValue when it changes
  function handleChange(e, key) {
    try {
      setSelectValues({ ...selectValues, [key]: e.target.value });
    } catch (e) {
      console.error(e);
}
  }

//Api that updates the Disposition in the Contact Attributes, attr
  async function updateDisposition(e) {
    e.preventDefault();
  
    try {
      if (onContact === true) {
        let updatedDisposition = selectValues;

        apiDisposition
          .put({
            routingProfile: routingProfile,
            instanceId: instanceId,
            contactId: contactstate.primarycontactid,
            attributes: updatedDisposition
          }, idToken)
          .then((res) => {
            setCustomerDisposition(true);

            // Check if the Modal is open (the agent didn't update the disposition before the contact ended)
            //If so, close the modal since the update was successful
            if (openModal == true) {
              setOpenModal(false);
              contact.clear();
            }
            return res;
          });
      } else {
        setSelectValues(defaultValues);
        setSubmitDisabled(true);
        console.error('No contact found.');
          }
    } catch (e) {
      console.error(e);
            }
          }
         
  return (
    <div>
      <Box>
        <form onSubmit={updateDisposition} id="Disposition Form">
          {dispositions ? (
    <>
              <TextField
                style={{ marginRight: '5px', marginBottom: '10px' }}
                disabled={true}
                InputLabelProps={{ shrink: true, style: { color: '#000000', fontSize: 18 } }}
                className="not_draggable"
                value={contactstate.primarycontactid}
                id="contactid"
                label="Current Contact"
                fullWidth
              />
 
              {Object.entries(dispositions).map(([key, value]) => (
                <div key={key + '-disposition-dev'}>
                  <TextField
                    style={{ paddingBottom: '10px' }}
                    id={key}
                    key={key + '-disposition-select'}
                    InputLabelProps={{ shrink: true, style: { color: '#000000', fontSize: 18 } }}
                    select
                    required
                    label={key}
                    className="not_draggable"
                    onChange={(e) => handleChange(e, key)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={selectValues[key]}
                  >
                    {value.map((val) =>
                      Object.entries(val).map(([k, v]) => (
                        <MenuItem id={k} key={key + '-disposition-value'} value={v}>
                          {v}
                        </MenuItem>
                      ))
                     )}
                  </TextField>
                </div>
              ))}
                  
              <Button type="submit" variant="contained" disabled={submitDisabled}>
                Submit
              </Button>
                   
              {openModal && (
                <DispositionModal
                  InputLabelProps={{ shrink: true, style: { color: '#000000', fontSize: 18 } }}
                  modalWidth={modalWidth}
                  modalHeight={modalHeight}
                  updateDisposition={updateDisposition}
                  dispositions={dispositions}
                  selectValues={selectValues}
                  handleChange={handleChange}
                  openModal={openModal}
                  submitDisabled={submitDisabled}
                />
              )}
    </>
          ) : (
            <div>Loading...</div>
          )}
        </form>
      </Box>
    </div>
  );
};

export default memo(CallDisposition);
