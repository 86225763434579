import React, { memo, useCallback, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import { ContactContext } from '../ContactContextProvider'
import { useConnected, useCallCompleted, useDestroy } from '../../hooks.js'


import ui from '../ui.js';

const ContactAttributes = () => {
const contactstate = useContext(ContactContext)
var attributefields = window.attributes

//Hook for behavior when Contact is connected
const onConnected = useCallback((c) => {
  try {
      const attr = c.getAttributes();
      const isCall = c.isSoftphoneCall()
      let currentContact = c.contactId;
      if (attr === null) { };
    // If contact is a phone call
      let findqueue = attributefields.find(o => o.label === 'Queue')
      if (findqueue) {
      ui.setText("contact_queueArn", c.getQueue().name)
    }

      for (let i = 0; i < attributefields.length; i++) {
        if (attributefields[i].label !== 'Queue') {
          if (attr[attributefields[i].name] !== undefined) {
      ui.setText(attributefields[i].id, attr[attributefields[i].name].value)
          } else {
          ui.setText(attributefields[i].id, '')
        }
        }
      }
  } catch (e) {
  }
}, []);
useConnected(onConnected);

const onDestroy = useCallback(() => {
    var findqueue = attributefields.find(o => o.label === 'Queue')
    if (findqueue) {
      ui.setText("contact_queueArn", '')
    }
    for (let i = 0; i < attributefields.length; i++) {
      if (attributefields.label !== 'Queue') {
      ui.setText(attributefields[i].id, '')
      }
    }
    }, []);
useDestroy(onDestroy);

  function setFields(attrFields) {
    return attrFields.map((attr, i) => {
      if (attr.label !== 'Queue') {
        if (attr.type === 'dropdown') {
          return <TextField style={{ margin: '3px' }}
            select
      className='not_draggable' 
      id={attr.id} 
      label={attr.label} 
            fullWidth
      />
        } else {
          return <TextField
            key={attr.id}
            style={{ margin: '3px' }}
      disabled={true}
            InputLabelProps={{ shrink: true, }}
      className='not_draggable'
      id={attr.id} 
      label={attr.label} 
            fullWidth
    />
    }
  }

  })
 
}

return (
  <div className='attributesContainer'>


      
      <div className='attribute'>

        {/* Create Queue Field to ensure it's on top and full width*/}
        <TextField style={{ margin: '3px' }}
          key='contact_queueArn'
      className='not_draggable' 
      id='contact_queueArn'
      label='Queue'
      disabled={true}
      fullWidth 
          InputLabelProps={{ shrink: true, }}
      />
       {setFields(attributefields)}
      </div>
  

  </div>
)



}

export default memo(ContactAttributes);
